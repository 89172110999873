import React from 'react';
import { motion } from 'framer-motion';
import { FaReact, FaPython, FaNodeJs, FaJsSquare } from 'react-icons/fa';
import { SiFlutter, SiFirebase, SiTensorflow } from 'react-icons/si';

const skillsData = [
  { name: 'Flutter', icon: <SiFlutter className="h-8 w-8 text-blue-500" /> },
  { name: 'Python', icon: <FaPython className="h-8 w-8 text-yellow-500" /> },
  { name: 'JavaScript', icon: <FaJsSquare className="h-8 w-8 text-yellow-400" /> },
  { name: 'React', icon: <FaReact className="h-8 w-8 text-blue-600" /> },
  { name: 'Node.js', icon: <FaNodeJs className="h-8 w-8 text-green-500" /> },
  { name: 'Machine Learning', icon: <SiTensorflow className="h-8 w-8 text-orange-500" /> },
  { name: 'Firebase', icon: <SiFirebase className="h-8 w-8 text-yellow-500" /> },
];

const Skills = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="w-full bg-white text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10"
    >
      <h2 className="text-3xl font-bold mb-8 text-center">Skills</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {skillsData.map((skill, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            className="flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 rounded-lg shadow-md p-4"
          >
            <div className="mb-4">{skill.icon}</div>
            <h3 className="text-lg font-semibold">{skill.name}</h3>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Skills;
