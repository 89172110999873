import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="flex flex-col items-center md:flex-row bg-white text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.7 }}
        className="md:w-1/3 flex justify-center mb-6 md:mb-0"
      >
        <img
          src="/mme.png"
          alt="Profile"
          className="w-40 h-40 md:w-48 md:h-48 rounded-full shadow-md border-2 border-gray-200"
        />
      </motion.div>
      <motion.div
        initial={{ x: 50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.7 }}
        className="md:w-2/3 md:pl-10 text-center md:text-left"
      >
        <h2 className="text-3xl font-bold mb-4">About Me</h2>
        <p className="text-lg leading-relaxed mb-4">
          I am a dedicated and highly motivated software developer with over 2.5 years of experience in various technologies including Flutter, Python, and JavaScript. I am passionate about building innovative solutions and constantly enhancing my skills.
        </p>
        <div className="space-y-4">
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.7 }}
            className="flex items-center justify-center md:justify-start"
          >
            <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 21v-2a4 4 0 00-3.45-3.95 12.08 12.08 0 01-7.55 0A4 4 0 002 19v2m16-4a4 4 0 010 8m-8 0a4 4 0 010-8m8-6a4 4 0 10-8 0 4 4 0 008 0z" />
            </svg>
            <p className="text-lg">
              <strong>Location:</strong> Wartanabada, Mogadishu, Somalia
            </p>
          </motion.div>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.8, duration: 0.7 }}
            className="flex items-center justify-center md:justify-start"
          >
            <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 8.5a7.5 7.5 0 0112.5 6.25A12 12 0 0116.75 20a12 12 0 01-6.25 1.25A7.5 7.5 0 012 8.5zM20 8v.02M20 16v.02M20 12h.02M20 20v.02M20 4v.02M16 20h.02M12 20h.02M8 20h.02M4 20h.02M4 16h.02M4 12h.02M4 8h.02M4 4h.02M12 4h.02M16 4h.02" />
            </svg>
            <p className="text-lg">
              <strong>Phone:</strong> +252612863271
            </p>
          </motion.div>
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.9, duration: 0.7 }}
            className="flex items-center justify-center md:justify-start"
          >
            <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12H8m8-4H8m8 8H8m-3 5h16a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <p className="text-lg">
              <strong>Email:</strong> ahmedyare6161@gmail.com
            </p>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default About;
