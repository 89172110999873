import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon, UserIcon, BriefcaseIcon, CodeIcon, PhoneIcon } from '@heroicons/react/solid';

const BottomNav = () => {
  return (
    <div className="fixed bottom-0 w-full bg-white text-gray-800 shadow-md md:hidden">
      <div className="flex justify-around py-3">
        <Link to="/" className="flex flex-col items-center text-blue-500 hover:text-blue-700">
          <HomeIcon className="h-6 w-6" />
          <span className="text-xs">Home</span>
        </Link>
        <Link to="/about" className="flex flex-col items-center text-blue-500 hover:text-blue-700">
          <UserIcon className="h-6 w-6" />
          <span className="text-xs">About</span>
        </Link>
        <Link to="/skills" className="flex flex-col items-center text-blue-500 hover:text-blue-700">
          <CodeIcon className="h-6 w-6" />
          <span className="text-xs">Skills</span>
        </Link>
        <Link to="/projects" className="flex flex-col items-center text-blue-500 hover:text-blue-700">
          <BriefcaseIcon className="h-6 w-6" />
          <span className="text-xs">Projects</span>
        </Link>
        <Link to="/contact" className="flex flex-col items-center text-blue-500 hover:text-blue-700">
          <PhoneIcon className="h-6 w-6" />
          <span className="text-xs">Contact</span>
        </Link>
      </div>
    </div>
  );
};

export default BottomNav;
