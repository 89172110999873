import React from 'react';
import { motion } from 'framer-motion';
import { FaUserCircle } from 'react-icons/fa';

const testimonialsData = [
  {
    quote: "Abdullahi's dedication to his work is unparalleled. His innovative solutions and attention to detail have greatly contributed to our project's success.",
    client: 'Saki'
  },
  {
    quote: 'Working with Abdullahi has been a fantastic experience. His expertise and professionalism are top-notch.',
    client: 'Ahmed'
  },
];

const Testimonials = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="w-full bg-white text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10"
    >
      <h2 className="text-3xl font-bold mb-8 text-center">Testimonials</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {testimonialsData.map((testimonial, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            className="flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 rounded-lg shadow-md p-4"
          >
            <FaUserCircle className="w-20 h-20 text-gray-400 mb-4" />
            <p className="text-lg italic mb-4 text-center">"{testimonial.quote}"</p>
            <h3 className="text-md font-semibold text-center">{testimonial.client}</h3>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Testimonials;
