import React from 'react';
import { motion } from 'framer-motion';
import { FaTasks, FaUtensils, FaShoppingCart, FaVoteYea, FaImage, FaFilm, FaFilePdf } from 'react-icons/fa';

const projectsData = [
  { name: 'Task Manager App', icon: <FaTasks className="h-8 w-8 text-blue-500" /> },
  { name: 'Restaurant Management System', icon: <FaUtensils className="h-8 w-8 text-green-500" /> },
  { name: 'Marketplace App', icon: <FaShoppingCart className="h-8 w-8 text-yellow-500" /> },
  { name: 'Voting System App', icon: <FaVoteYea className="h-8 w-8 text-red-500" /> },
  { name: 'Image Narrative and Audio Conversion Tool', icon: <FaImage className="h-8 w-8 text-purple-500" /> },
  { name: 'Movie Information Portal', icon: <FaFilm className="h-8 w-8 text-indigo-500" /> },
  { name: 'PDF Content Analyzer', icon: <FaFilePdf className="h-8 w-8 text-pink-500" /> },
];

const Projects = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="w-full bg-white text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10"
    >
      <h2 className="text-3xl font-bold mb-8 text-center">Recent Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projectsData.map((project, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            className="flex flex-col items-center bg-gradient-to-r from-gray-100 to-gray-200 rounded-lg shadow-md p-4"
          >
            <div className="mb-4">{project.icon}</div>
            <h3 className="text-lg font-semibold text-center">{project.name}</h3>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Projects;
