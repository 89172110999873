import React from 'react';
import { motion } from 'framer-motion';
import About from './About';
import Skills from './Skills';
import Projects from './Projects';
import Testimonials from './Testimonials';
import BottomNav from './BottomNav';

const Home = () => {
  return (
    <div className="home bg-gradient-to-r from-blue-500 to-teal-500 min-h-screen flex flex-col items-center text-white p-6">
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="text-center md:text-left"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Welcome to My Portfolio</h1>
        <p className="text-lg md:text-xl mb-8">
          I am Abdullahi Omar Ahmed, a passionate software developer.
        </p>
        <div className="flex justify-center md:justify-start space-x-6 mb-10">
          <a
            href="mailto:ahmedyare6161@gmail.com"
            className="bg-white text-blue-500 px-6 py-2 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
          >
            Contact Me
          </a>
          <a
            href="https://www.linkedin.com/in/abdullhi-omar-9768b9262/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-blue-500 px-6 py-2 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
          >
            LinkedIn
          </a>
          <a
            href="/Mcv.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white text-blue-500 px-6 py-2 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
          >
            Download CV
          </a>
        </div>
      </motion.div>
      <div className="w-full bg-gradient-to-r from-teal-500 to-green-500 shadow-inner">
        <About />
      </div>
      <div className="w-full  text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10">
        <Skills />
      </div>
      <div className="w-full  text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10">
        <Projects />
      </div>
      <div className="w-full  text-gray-800 rounded-lg shadow-lg p-6 md:p-10 mb-10">
        <Testimonials />
      </div>
      <BottomNav />
    </div>
  );
};

export default Home;
