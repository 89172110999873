import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center md:justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold mx-auto">
              My Portfolio
            </Link>
          </div>
          <div className="hidden md:flex space-x-4 items-center">
            <Link to="/" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
              Home
            </Link>
            <Link to="/about" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
              About
            </Link>
            <Link to="/skills" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
              Skills
            </Link>
            <Link to="/projects" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
              Projects
            </Link>
            {/* <Link to="/experience" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
              Experience
            </Link> */}
            <Link to="/contact" className="hover:bg-indigo-700 px-3 py-2 rounded-md text-sm font-medium transition duration-300">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
